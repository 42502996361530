const AuthActionTypes = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    USER_LOADED_SUCCESS: "USER_LOADED_SUCCESS",
    USER_LOADED_FAILURE: "USER_LOADED_FAILURE",
    AUTHENTICATED_SUCCESS: "AUTHENTICATED_SUCCESS",
    AUTHENTICATED_FAILURE: "AUTHENTICATED_FAILURE",
    LOGOUT: "LOGOUT",
}

export default AuthActionTypes;